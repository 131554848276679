import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../Style/Fit.css"
import addedvalue from "../../assets/images/addedvalue_old.png";
import addedvalueMobile from "../../assets/images/addedvalue_mobile_old.jpg";
import axios from "axios"
import { BASE_API_URL } from "../../config/constants"
import 'swiper/css';
import { HashRouter, NavLink, BrowserRouter as Router, useLocation, Switch, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';


const BenefitComponent = () => {
    const location = useLocation();

    const[postData, setPostData] = useState([]);
    const[postData1, setPostData1] = useState([]);
    const[postData2, setPostData2] = useState([]);
    const[postData3, setPostData3] = useState([]);
    const[postData4, setPostData4] = useState([]);
    const[postData5, setPostData5] = useState([]);
    const[postData6, setPostData6] = useState([]);
    
    useEffect(() => {
        const fetchPostData = async () => {
            try{
               
                 const apiUrl = BASE_API_URL+'/posts/detail/post/*78448C4EC86569D022A6B3B280F29A53219E6F51';
                 const response = await axios.get(apiUrl);
                 setPostData(response.data.post);
               
            
            
                const apiUrl2 = BASE_API_URL+'/posts/detail/post/*DC0A37B357399CA8EEB1DE383320CD6EF2F7A519';
                const response2 = await axios.get(apiUrl2);
                setPostData1(response2.data.post);
               
           
                const apiUrl3 = BASE_API_URL+'/posts/detail/post/*1113E7CB6F34DE3FC266924B5DEFF9E3EDE871EB';
                const response3 = await axios.get(apiUrl3);
                setPostData2(response3.data.post);
               
                const apiUrl4 = BASE_API_URL+'/posts/detail/post/*23CF1CC2BF86B8A3936FD2E395F19AC8383FE1C0';
                const response4 = await axios.get(apiUrl4);
                setPostData3(response4.data.post);
              
                const apiUrl5 = BASE_API_URL+'/posts/detail/post/*75B37A560FD33E7D493D533E5CFFB59E6F994A56';
                const response5 = await axios.get(apiUrl5);
                setPostData4(response5.data.post);
               
                const apiUrl6 = BASE_API_URL+'/posts/detail/post/*BD65ABAA9A9A09494A3A3C5ED1CE26323F8E4137';
                const response6 = await axios.get(apiUrl6);
                setPostData5(response6.data.post);
               
                const apiUrl7 = BASE_API_URL+'/posts/detail/post/*B0BA7173AA3E6A7E7666FBE15683D3A59CAFCD13';
                const response7 = await axios.get(apiUrl7);
                setPostData6(response7.data.post);
                return    
            
            } catch (error) {
                console.error('Error fetching data:', error);
                setPostData({});
                // setIsLoading(false); // Set isLoading ke false jika terjadi error
              }
            };
            fetchPostData();
          }, []);

    if(location.pathname === "/home"){
       return(
        <div className="icon_ben">
        <img src={postData.Img_path} alt={postData.Img_path} className="icon"></img>
        <div className="txticon">
            <p dangerouslySetInnerHTML={{ __html: postData.Content_tx }}></p>
        </div>
    </div>
       )
    }
    else if(location.pathname === "/home/benefit/original"){
        return(
            <div className="icon_ben">
                <img src={postData1.Img_path} alt={postData1.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData1.Content_tx }}></p>
                </div>
            </div>
        );
    }

    else if(location.pathname === "/home/benefit/free"){
        return(
            <div className="icon_ben">
                <img src={postData2.Img_path} alt={postData2.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData2.Content_tx }}></p>
                </div>
            </div>
        )
    }
    else if(location.pathname === "/home/benefit/insurance"){
        return(
            <div className="icon_ben">
                <img src={postData3.Img_path} alt={postData3.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData3.Content_tx }}></p>
                </div>
            </div>
        )
    }
    else if(location.pathname === "/home/benefit/payment"){
        return(
            <div className="icon_ben">
                <img src={postData4.Img_path} alt={postData4.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData4.Content_tx }}></p>
                </div>
            </div>
        )
    }
    else if(location.pathname === "/home/benefit/reward"){
        return(
            <div className="icon_ben">
                <img src={postData5.Img_path} alt={postData5.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData5.Content_tx }}></p>
                </div>
            </div>
        )   
    }
    else if(location.pathname === "/home/benefit/premium"){
        return(
            <div className="icon_ben">
                <img src={postData6.Img_path} alt={postData6.Img_path} className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData6.Content_tx }}></p>
                </div>
            </div>
        )
    }
    else {
        return(
            <div className="icon_ben">
                <img src={postData.Img_path} alt="Icon" className="icon"></img>
                <div className="txticon">
                <p dangerouslySetInnerHTML={{ __html: postData.Content_tx }}></p>
                </div>
            </div>
        )
    }
}
   

function BenefitBelanja(){
    const location = useLocation();

    const[postData, setPostData] = useState([]);
    const[postData1, setPostData1] = useState([]);
    const[postData2, setPostData2] = useState([]);
    const[postData3, setPostData3] = useState([]);
    const[postData4, setPostData4] = useState([]);
    const[postData5, setPostData5] = useState([]);
    const[postData6, setPostData6] = useState([]);
    
    useEffect(() => {
    const fetchPostData = async () => {
        try{
           
             const apiUrl = BASE_API_URL+'/posts/detail/post/*78448C4EC86569D022A6B3B280F29A53219E6F51';
             const response = await axios.get(apiUrl);
             setPostData(response.data.post);
        
            const apiUrl2 = BASE_API_URL+'/posts/detail/post/*DC0A37B357399CA8EEB1DE383320CD6EF2F7A519';
            const response2 = await axios.get(apiUrl2);
            setPostData1(response2.data.post);
       
            const apiUrl3 = BASE_API_URL+'/posts/detail/post/*1113E7CB6F34DE3FC266924B5DEFF9E3EDE871EB';
            const response3 = await axios.get(apiUrl3);
            setPostData2(response3.data.post);
           
            const apiUrl4 = BASE_API_URL+'/posts/detail/post/*23CF1CC2BF86B8A3936FD2E395F19AC8383FE1C0';
            const response4 = await axios.get(apiUrl4);
            setPostData3(response4.data.post);
          
            const apiUrl5 = BASE_API_URL+'/posts/detail/post/*75B37A560FD33E7D493D533E5CFFB59E6F994A56';
            const response5 = await axios.get(apiUrl5);
            setPostData4(response5.data.post);
           
            const apiUrl6 = BASE_API_URL+'/posts/detail/post/*BD65ABAA9A9A09494A3A3C5ED1CE26323F8E4137';
            const response6 = await axios.get(apiUrl6);
            setPostData5(response6.data.post);
           
            const apiUrl7 = BASE_API_URL+'/posts/detail/post/*B0BA7173AA3E6A7E7666FBE15683D3A59CAFCD13';
            const response7 = await axios.get(apiUrl7);
            setPostData6(response7.data.post);
            return    
        
        } catch (error) {
            console.error('Error fetching data:', error);
            setPostData({});
            // setIsLoading(false); // Set isLoading ke false jika terjadi error
          }
        };
        fetchPostData();
      }, []);


    return(
        <div className="container"> 
            {/* <div className="ben">
                <h2 className="hed-ben">Added Value</h2><br/>
                <center>
                    <hr className="h2r" /> 
                </center>
            </div> */}
            <div className="box">
            <div>
                <img src={addedvalue} alt="Added Value" style={{width: "200%", marginBottom: "30px"}}></img>
                    {/* <BenefitComponent /> */}
            </div>
            <div>
            <div className="list">
            <div className="inner-container">
            {/* <NavLink to="/home" exact activeClassName="active-link">
                       <button className="circle">
                            <div className="text">1</div>
                        </button>
                        </NavLink>           
                        <NavLink to="/home" className="txtlink" dangerouslySetInnerHTML={{ __html: postData.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/original">
                     <button className="circle">
                            <div className="text">2</div>
                            </button>
                            </NavLink>
                         <NavLink to="/home/benefit/original" dangerouslySetInnerHTML={{ __html: postData1.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/free">
                    <button className="circle">
                            <div className="text">3</div>
                            </button>
                            </NavLink>
                            <NavLink to="/home/benefit/free" dangerouslySetInnerHTML={{ __html: postData2.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/insurance">
                    <button className="circle">
                            <div className="text">4</div>
                        </button>
                        </NavLink>
                        <NavLink to="/home/benefit/insurance"dangerouslySetInnerHTML={{ __html: postData3.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/payment">
                  <button className="circle">
                            <div className="text">5</div>
                        </button>
                        </NavLink>
                        <NavLink  to="/home/benefit/payment" dangerouslySetInnerHTML={{ __html: postData4.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/reward">
                    <button className="circle">
                            <div className="text">6</div>
                        </button>
                        </NavLink>
                        <NavLink  to="/home/benefit/reward" dangerouslySetInnerHTML={{ __html: postData5.Content_tx }}></NavLink>
                    </div>
                    <div className="inner-container">
                    <NavLink to="/home/benefit/premium">
                    <button className="circle">
                    <div className="text">7</div>
                        </button>
                        </NavLink>
                        <NavLink to="/home/benefit/premium" dangerouslySetInnerHTML={{ __html: postData6.Content_tx }}></NavLink> */}
                    </div>  
              
            </div>
            </div>
            </div>


            <div className="md_benefit">
            <img src={addedvalueMobile} alt="Added Value" style={{width: "100%", marginBottom: "30px"}}></img>
            {/* <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
            >
            <SwiperSlide className="bnn">
                <img src={postData.Img_path} alt="Los Angeles"/>
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData.Content_tx }}>
                  
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData1.Img_path} alt="Chicago" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData1.Content_tx }}>
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData2.Img_path} alt="New york" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData2.Content_tx }}>
                 
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData3.Img_path} alt="New york" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData3.Content_tx }}>
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData4.Img_path} alt="New york" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData4.Content_tx }}>
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData5.Img_path} alt="New york" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData5.Content_tx }}>
                </p>
                </center>
            </SwiperSlide>
            <SwiperSlide className="bnn">
                <img src={postData6.Img_path} alt="New york" />
                <center>
                <p className="txben" dangerouslySetInnerHTML={{ __html: postData6.Content_tx }}>
                
                </p>
                </center>
            </SwiperSlide>
            </Swiper> */}
            </div>
        
        </div>
    );
}

export default BenefitBelanja;